<template>
    <div id="app">
        <Loader v-if="isLoading" />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
<script setup>
const nuxtApp = useNuxtApp();
const isLoading = ref(false);
useHead({
    title: 'County Mall Shopping Centre',
    link: [
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
        {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Mulish:wght@200;300;400;500;600;700;800;900;1000&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap',
        },
    ],
    meta: [
        {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, maximum-scale=1',
        },
        {
            name: 'google-site-verification',
            content: 'Qp9LhoVvJq1RvCHqxVG1RAQIS_O9mdTpo35uMcx7u4g',
        },
    ],
    script: [
        {
            children: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1874389036313451');
            fbq('track', 'PageView');
            `,
            tagPosition: 'head',
        },
    ],
    noscript: [
        {
            children: `
                <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1874389036313451&ev=PageView&noscript=1" />`,
            tagPosition: 'head',
        },
    ],
});
nuxtApp.hook('page:start', () => {
    isLoading.value = true;
});
nuxtApp.hook('page:finish', () => {
    isLoading.value = false;
});
</script>
<style lang="scss" scoped>
#app {
    font-family: $font-family-base;
    color: $text;
    width: 100%;
    overflow-x: hidden;
}
</style>
