<template>
    <NuxtLayout class="error">
        <div class="md:container mx-auto px-4 py-8 lg:px-12 md:py-12 lg:py-24">
            <div class="text-center">
                <div>
                    <h1 class="text-3xl font-medium">
                        <strong>Page not found</strong>
                    </h1>
                    <p class="mt-8">It seems like you've taken a wrong turn.</p>
                    <p>
                        The page you're looking for may have been moved,
                        deleted, or never existed in the first place.
                    </p>
                    <div class="mt-8">
                        <button class="btn btn-primary" @click="handleError">
                            Go to the Home Page
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup>
const handleError = () => {
    clearError({
        redirect: '/',
    });
};
</script>

<style lang="scss" scoped>
.error {
}
</style>
